import Image from 'next/image'
import Link from 'next/link'
import styles from '../styles/Home.module.css'
import Layout from '../Components/Layout';
import { news } from './api/alerts';
import NewsItem from "../Components/NewsItem"

export default function Home({news}) {
  return (
    <div className={styles.container}>
       
       
       <Layout>
      <main className={styles.main}></main>

<div className={styles.homenews}>
  <h2><Link href="/hub"><a title="Cyber Security Alerts">Security Alerts</a></Link></h2>
  <h3>Stay vigilant and informed with our Security Alerts</h3>
    <p className={styles.hometxt}>Updates on emerging threats and protective measures</p>
       </div>
        {news.length === 0 && <h3>No News</h3>}
        {news.map((item) => (
          <NewsItem key={item.id} news={item} />
        ))}
      </Layout>
    </div>
  )
}
 export async function getStaticProps() {
   return {
    props: { news: news.slice(0, 4) },
    revalidate: false,
   };
  }
